(function() {
    'use strict';

    angular
        .module('pcsApp')
        .controller('FooterController', FooterController);

    FooterController.$inject = ['$state'];

    function FooterController ($state) {
    }
})();
